import React from "react";
import "./style.scss";
import { ILevelUp } from "icons/ILevelUp";
import LevelTag from "components/LevelTag";
interface NotiEffectProps {
  type: "level-up" | "new-item";
  levelType?: "user-level" | "process-level";
  newItemType?: "user-item" | "process-item";
  process?: any;
  user?: any;
  style?: React.CSSProperties;
}

const NotiEffect: React.FC<NotiEffectProps> = ({
  type,
  process,
  levelType,
  newItemType,
  user,
  style = {},
}) => {
  return (
    <div className="notiEffectContainer" style={style}>
      <div>
        {
          type === "level-up" && (
            <>
              {
                levelType && (
                  <div className="levelTagContainer">
                    <LevelTag 
                      type={levelType === "process-level" ? "process" : "worker"} 
                      screen="notiEffect"
                    />
                  </div>
                )
              }
              <div className="levelUpIconWrapper">
                <ILevelUp />
              </div>
              <p className="levelUp">
                レベルアップ！
              </p>
            </>
          )
        }
        {
          type === "new-item" && (
            <>
              {
                newItemType === "process-item" && (
                  <>
                    <div className="newItemWrapper">
                      <img src={process?.new_item_image || ''} alt="new item" className="newItemImage" />
                    </div>
                    <p className="btn-item-name">
                      {process?.new_item_name || ''}
                    </p>
                    <p className="iGotIt">
                      を獲得しました！
                    </p>
                  </>
                )
              }
              {
                newItemType === "user-item" && (
                  <>
                    <div className="newItemWrapper">
                      <img src={user?.new_item_image || ''} alt="user new item" className="newItemImage" />
                    </div>
                    <p className="btn-item-name">
                      {user?.new_item_name || ''}
                    </p>
                    <p className="iGotIt">
                      を獲得しました！
                    </p>
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
}

export default NotiEffect;