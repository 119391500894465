import { Button, Modal, Input, Form } from "antd";
import { IClose } from "icons/IClose";
import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import constant from "constant/action";
import axios from "config/axios";
import { toast } from "utils/notification";
import "./style.scss";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  accountName: any;
  currentUserRecord: any;
}

interface Value {
  password: string;
}

interface ChangePasswordParams {
  user_id: number;
  password: string;
}

const ChangePasswordModal: React.FC<Props> = ({
  open,
  setOpen,
  accountName,
  currentUserRecord
}) => {
  const [form] = Form.useForm();
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const handleChangePassword = async (value: Value) => {
    if (!value.password) {
      setRequiredError(true);
      return;
    }

    try {
      const data: ChangePasswordParams = {
        password: value.password,
        user_id: currentUserRecord.id,
      };
      const url = constant.CHANGE_PASSWORD;
      const response = await axios.put(url, data);
      if (response && response.data) {
        setUpdatePasswordSuccess(true);
        form.resetFields();
      }
    } catch (error: any) {
      if (error && error.response) {
        const errorMessage = error.response?.data?.error;
        toast('error', errorMessage);
      }
    }
  }

  useEffect(() => {
    let updatePasswordSuccessTimeout: NodeJS.Timeout;
    if (updatePasswordSuccess) {
      updatePasswordSuccessTimeout = setTimeout(() => {
        setUpdatePasswordSuccess(false);
      }, 5000);
    }
    return () => clearTimeout(updatePasswordSuccessTimeout);
  }, [updatePasswordSuccess]);

  useEffect(() => {
    let requiredErrorTimeout: NodeJS.Timeout;
    if (requiredError) {
      requiredErrorTimeout = setTimeout(() => {
        setRequiredError(false);
      }, 5000);
    }
    return () => clearTimeout(requiredErrorTimeout);
  }, [requiredError]);
  return (
    <Modal
      centered
      open={open}
      footer={null}
      closeIcon={null}
      className="changePasswordModalContainer"
    >
      <div
        className="flex justify-end cursor-pointer"
        onClick={() => {
          setOpen(false);
        }}
      >
        <IClose />
      </div>
      <div className="flex flex-col items-center px-[20px]  pt-[41px] pb-[84px]">
          <p className="text-[20px] not-italic tracking-[4px] text-[#7b7b7b]">
            {accountName}
          </p>
          <p className="font-noto-sans text-[14px] not-italic font-bold text-[#7b7b7b] pt-[23px] pb-[5px]">
            新しいパスワード
          </p>
          <Form
            name="form"
            form={form}
            className="w-[100%]"
            initialValues={{ accountName: "" }}
            onFinish={(values) => {
              handleChangePassword(values);
            }}
          >
            <Form.Item name="password">
              <Input className="h-[60px] account-name-input" type="password" />
            </Form.Item>
            {requiredError && (
              <div className="font-noto-sans font-bold mt-5 text-center text-[16px] text-red-600">
                このフィールドは入力必須です
              </div>
            )}
            <div className="flex justify-center">
              <Button htmlType="submit" className="button-change-pw mt-[20px]">
                パスワード変更
              </Button>
            </div>
          </Form>
          {updatePasswordSuccess && (
            <p className="pt-[27px] font-noto-sans font-bold not-italic text-[14px] text-[#006ab2]">
              パスワードを保存しました
            </p>
          )}
        </div>
    </Modal>
  )
}

export default ChangePasswordModal