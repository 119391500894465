import { set } from 'lodash';
import Axios from "axios";

export const BASE_URL = process.env.REACT_APP_SERVER_API;

export const baseURL = `${BASE_URL}/`;

export const axios = Axios.create({
  baseURL,
});

axios.interceptors.request.use((request) => {
  let token = request.data?.request_type 
    && request.data?.request_type === "secondary" 
    && localStorage.getItem("secondary_token") 
    ? localStorage.getItem("secondary_token") 
    : localStorage.getItem("token");
  if (!token) {
    return request;
  }
  set(request, "headers.Authorization", `Bearer ${token}`);
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      window.location.href = "/admin/login";
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axios;
