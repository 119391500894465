import axios from "config/axios";
import constant from "constant/action";

export const handleSaveLog = async (data: object) => {
  const submitLogData = new FormData();
  submitLogData.append("log_info", JSON.stringify(data));
  const url = constant.ADMIN_LOGS;
  try {
    await axios.post(url, submitLogData);
  } catch (error) {
    // 
  }
}
