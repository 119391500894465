import { notification } from 'antd';
type type = 'success' | 'info' | 'warning' | 'error';
type placement = 'topRight' | 'topLeft' | 'bottom' | 'top' | 'bottomLeft' | 'bottomRight';
export const toast = (
  type: type = 'info',
  message = 'INFO',
  description = '',
  placement: placement = 'topRight'
) => {
  notification.open({
    type,
    message,
    description,
    placement
  });
};