import { Box } from "@mui/material";

export const IDocument = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.476"
        height="32.15"
        viewBox="-2321 -2258 22.476 32.15"
      >
        <g data-name="グループ 2618">
          <path
            d="M-2300.074-2252.201h-2.392a.388.388 0 0 0-.387.387v2.325a.388.388 0 0 1-.388.388h-13.116a.388.388 0 0 1-.387-.388v-2.325a.388.388 0 0 0-.388-.387h-2.318a1.55 1.55 0 0 0-1.55 1.55v23.25c0 .857.694 1.55 1.55 1.55h19.376a1.55 1.55 0 0 0 1.55-1.55v-23.25a1.55 1.55 0 0 0-1.55-1.55m-11.43 7.666h7.836a.969.969 0 1 1 0 1.937h-7.835a.969.969 0 0 1 0-1.937m0 3.875h7.835a.969.969 0 1 1 0 1.937h-7.835a.969.969 0 0 1 0-1.937m-4.263 5.812h-.302a.969.969 0 1 1 0-1.937h.302a.969.969 0 1 1 0 1.937m0-3.875h-.302a.969.969 0 1 1 0-1.937h.302a.969.969 0 1 1 0 1.937m0-3.875h-.302a.969.969 0 0 1 0-1.937h.302a.969.969 0 1 1 0 1.937m12.204 7.75h-7.941a.969.969 0 1 1 0-1.937h7.941a.969.969 0 1 1 0 1.937"
            fill="#7b7b7b"
            fill-rule="evenodd"
            data-name="パス 7833"
          />
          <path
            d="M-2306.84-2254.74c-.574-.194-.993-.729-.985-1.334a1.897 1.897 0 0 0-1.897-1.926h-.08c-1.027 0-1.938.816-1.899 1.842a1.437 1.437 0 0 1-.979 1.416c-1.231.416-2.127.797-2.127 2.167v1.163c0 .214.174.387.388.387h9.307a.388.388 0 0 0 .388-.387v-1.163c0-1.368-.888-1.75-2.116-2.165"
            fill="#7b7b7b"
            fill-rule="evenodd"
            data-name="パス 7834"
          />
        </g>
      </svg>
    </Box>
  );
};
