import React, { useEffect, useState } from "react";
import "./style.scss";
import { getRoundedValueOfCycleTime } from "utils/calculation";

interface CycleTimeChartProps {
  currentCycleTime: number;
  standardCycleTime: number;
  cycleTimeNormalRange: number;
  mileStoneHeight: string;
  barHeight: string;
  fontSize: string;
  legendStyleType: "work" | "skill-check";
}

const CycleTimeChart: React.FC<CycleTimeChartProps> = ({
  currentCycleTime,
  standardCycleTime,
  cycleTimeNormalRange,
  mileStoneHeight = "30px",
  barHeight = "20px",
  fontSize = "9px",
  legendStyleType = "skill-check"
}) => {
  const [max, setMax] = useState<number | undefined>();
  const [lowerBound, setLowerBound] = useState<number | undefined>();
  const [upperBound, setUpperBound] = useState<number | undefined>();
  const [innerHighlightWidth, setInnerHighlightWidth] = useState<number | undefined>();
  const [currentCTPosition, setCurrentCTPosition] = useState<number | undefined>();

  useEffect(() => {
    const upperRange = (1 + (cycleTimeNormalRange / 100));
    const lowerRange = (1 - (cycleTimeNormalRange / 100));
    const upperBoundValue = getRoundedValueOfCycleTime(standardCycleTime, upperRange);
    const lowerBoundValue = getRoundedValueOfCycleTime(standardCycleTime, lowerRange);

    setLowerBound(lowerBoundValue);
    setUpperBound(upperBoundValue);
    setMax(standardCycleTime * 2);
    setInnerHighlightWidth(((upperBoundValue - lowerBoundValue) / (standardCycleTime * 2)) * 100);
    if (currentCycleTime > (standardCycleTime * 2)) {
      setCurrentCTPosition(100);
    } else {
      setCurrentCTPosition((currentCycleTime / (standardCycleTime * 2)) * 100);
    }
  }, [
    currentCycleTime,
    standardCycleTime,
    cycleTimeNormalRange
  ])

  if (
    max === undefined
    || lowerBound === undefined
    || upperBound === undefined
    || innerHighlightWidth === undefined
    || currentCTPosition === undefined
  ) {
    return (
      <></>
    )
  }
  return (
    <div 
      className="CycleTimeChart-container"
      style={{ 
        height: '110px',
        fontSize: fontSize,
      }}
    >
      <div 
        className="wholeProgressBar"
        style={{ height: barHeight }}
      >
        <div 
          className="innerHighlighter"
          style={{
            width: `${innerHighlightWidth || 0}%`
          }}
        ></div>

        <div 
          className="minMileStone"
          style={{
            height: mileStoneHeight
          }}
        >
          <div className="value">0秒</div>
        </div>
        {
          ((lowerBound / max) * 100) > 0 && (
            <div 
              className="lowerBoundMileStone"
              style={{
                left: `${(lowerBound / max) * 100}%`,
                height: mileStoneHeight
              }}
            >
              <div className="value">{lowerBound}秒</div>
            </div>
          )
        }
        {
          ((upperBound / max) * 100) > 0 && (
            <div 
              className="upperBoundMileStone"
              style={{
                left: `${(upperBound / max) * 100}%`,
                height: mileStoneHeight
              }}
            >
              <div className="value">{upperBound}秒</div>
            </div>
          )
        }
        {
          max > 0 && (
            <div 
              className="maxMileStone"
              style={{
                height: mileStoneHeight
              }}
            >
              <div className="value">{max}秒</div>
            </div>
          )
        }

        <div 
          className="medianMileStone"
          style={{
            height: mileStoneHeight,
            left: standardCycleTime > 0 ? '50%' : 0
          }}
        >
          {/* <div className="label">
            <p>標準</p>
            <p>CT</p>
          </div> */}
          <div 
            className="value"
            style={{ 
              display: currentCycleTime === standardCycleTime ? 'none' : 'block',
            }}
          >
            {standardCycleTime}秒
          </div>
        </div>
        <div 
          className="currentMileStone"
          style={{
            left: `${currentCTPosition}%`,
            height: mileStoneHeight
          }}
        >
          {/* <div className="label">
            <p>現在</p>
            <p>CT</p>
          </div> */}
          <div className="value">{currentCycleTime}秒</div>
        </div>
      </div>

      <div 
        className="legends"
        style={{
          bottom: legendStyleType === "work" ? '0px' : '5px',
          left: legendStyleType === "work" ? '-100px' : 0,
          width: legendStyleType === "work" ? "100px" : "100%",
          paddingBottom: legendStyleType === "work" ? "0px" : "5px",
          flexDirection: legendStyleType === "work" ? "column" : "row",
          height: legendStyleType === "work" ? "100%" : "auto",
          justifyContent: legendStyleType === "work" ? "center" : "flex-start",
        }}
      >
        <div 
          className="standardCT"
          style={{
            marginRight: legendStyleType === "work" ? "0px" : "40px",
            marginBottom: legendStyleType === "work" ? "10px" : "0",
            width: legendStyleType === "work" ? "25px" : "20px"
          }}
        >
          <p
            style={{
              right: legendStyleType === "work" ? "-50px" : "-35px",
              bottom: legendStyleType === "work" ? "-9px" : "-7px"
            }}
          >標準CT</p>
        </div>
        <div 
          className="currentCT"
          style={{
            marginTop: legendStyleType === "work" ? "10px" : "0",
            width: legendStyleType === "work" ? "25px" : "20px"
          }}
        >
          <p
            style={{
              right: legendStyleType === "work" ? "-50px" : "-35px",
              bottom: legendStyleType === "work" ? "-9px" : "-7px"
            }}
          >現在CT</p>
        </div>
      </div>
    </div>
  )
}

export default CycleTimeChart;