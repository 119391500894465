import { configureStore } from '@reduxjs/toolkit';
// import todoSlice from './todo-slice'
// import authReducer from './auth/auth';
// import messageReducer from './message/message';
import rootReducer from "./duck";

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootSaga from "./sagas";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
