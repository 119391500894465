import { Button, Col, Progress, Row } from "antd";
import AdminLayout from "../adminLayout";
import "./style.scss";
import moment from "moment/moment";
import { IPrevious } from "../../../icons/IPrevious";
import { INext } from "../../../icons/INext";
import Chart from "../../../components/chart";
import { useEffect, useState } from "react";
import axios from "config/axios";
import constant from "constant/action";
import CycleTimeChart from "components/CycleTimeChart";
import NewAvatarImage, { ICharacterFullImage } from "components/NewAvatarImage";
import LevelTag from "components/LevelTag";


const ProgressContent = (props: any) => {
  const { item } = props;
  return (
    <>
      <div className="skill-check-content">
        <div className="nameLevel">
          <p className="text">
            {item?.name || ''}
          </p>
          {/* <p className="text">
            Lv.{item?.level + 1 || ''}
          </p> */}
          <LevelTag type="process" screen="working" level={(+item?.level || 0) + 1} />
        </div>
        <Progress
          className="progressBar"
          showInfo={false}
          percent={item?.percent_to_boost_level || 0}
          strokeColor="#83d59f"
          size="small"
        />

        <div className="cumulativeTimeWrapper">
          <div className="total">
            <p>
              今までの作業時間<br />(時間)
            </p>
            <p>
              {(Math.round(((item?.total_cumulative_time || 0) / 3600) * 100) / 100).toFixed(2)}
            </p>
          </div>
          <hr className="vertical-line" />
          <div className="average">
            <p>
              平均サイクルタイム<br />(秒/個)
            </p>
            <p>
              {item?.average_cumulative_time}
            </p>
          </div>
        </div>
        {/* <Chart 
          fontSize="9px" 
          height="20" 
          borderHeight="30" 
          normalWidth="12" 
          currentCycleTime={item?.current_cycle_time} 
          standardCycleTime={item?.standard_cycle_time}
          cycleTimeNormalRange={Number(item?.cycle_time_normal_range)}
          dropRedText={true}
        /> */}
        <CycleTimeChart 
          currentCycleTime={item?.current_cycle_time || 0}
          standardCycleTime={item?.standard_cycle_time || 0}
          cycleTimeNormalRange={Number(item?.cycle_time_normal_range) || 0}
          mileStoneHeight="30px"
          barHeight="20px"
          fontSize="9px"
          legendStyleType="skill-check"
        />
      </div>
    </>
  );
};
const SkillCheck = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [currentUserRecord, setCurrentUserRecord] = useState<any>(null);
  const [fullCharacterImage, setFullCharacterImage] = useState<ICharacterFullImage | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const url = constant.ADMIN_USERS;
        const response = await axios.get(url, {
          params: {
            page: page
          }
        });
        const { data } = response;
        if (!totalCount) {
          setTotalCount(data.meta?.total_count || 0);
        }
        setCurrentUserRecord(data.data?.[0]);
      } catch (error) {
        // 
      }
    };

    fetchUserData();
  }, [page])

  useEffect(() => {
    if (currentUserRecord) {
      const mainCharacter = currentUserRecord.user_characters?.find((item: any) => item.is_main === true);

      const bodyImage = mainCharacter.character.image_path;

      const hatItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "hat");
      const hatImage = hatItem?.user_items?.item?.asset?.image_path || undefined;

      const clothItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "cloth");
      const clothImage = clothItem?.user_items?.item?.asset?.image_path || undefined;
      
      const shoeItem = mainCharacter.user_character_items.find((item: any) => item?.user_items?.item?.item_type === "shoe");
      const shoeImage = shoeItem?.user_items?.item?.asset?.image_path || undefined;

      const gender = mainCharacter.user_character_items[0]?.user_items?.item?.gender || undefined;

      setFullCharacterImage({
        body: bodyImage,
        hat: hatImage,
        cloth: clothImage,
        shoe: shoeImage,
        gender: gender,
      });
    }
  }, [currentUserRecord])

  const movePrevRecord = () => {
    if (page === 1) {
      return;
    }

    setPage((prev) => prev - 1);
    setFullCharacterImage(null);
  }

  const moveNextRecord = () => {
    if (page === totalCount) {
      return;
    }

    setPage((prev) => prev + 1);
    setFullCharacterImage(null);
  }

  return (
    <>
      <AdminLayout>
          <div className="skill-check-container">
            <div className="accountSection">
              <div className="accountInfo">
                <div className="label">
                  <div className="btn-skill-check">
                    作業者名
                  </div>
                  <div className="btn-skill-check">
                    作業者コード
                  </div>
                </div>
                <div className="data">
                  <p className="text-name">{currentUserRecord?.name || ''}</p>
                  <p className="text-name">{currentUserRecord?.account_name || currentUserRecord?.account_id}</p>
                </div>
              </div>
              <LevelTag type="worker" level={+currentUserRecord?.level || 0} screen="skillCheck" />
              {
                fullCharacterImage !== null && (
                  <NewAvatarImage 
                    fullImage={fullCharacterImage}
                  />
                )
              }
            </div>
            <div className="historySection">
              <p className="title">
                アプリ操作履歴
              </p>
              <div className="operation-history-scroll">
                <div className="operation-history-container">
                  <div className="tableContainer">
                    <Row gutter={[16, 16]}>
                      <Col span={12} className="typeColumn">
                        <p>種別</p>
                      </Col>
                      <Col span={12} className="dateColumn">
                        <p>日時</p>
                      </Col>
                    </Row>
                    {(currentUserRecord?.user_activities || []).map((item: any) => (
                      <Row gutter={[16, 16]} className="bodyRow" key={item.id}>
                        <Col span={12} className="typeDataColumn">
                          <p>
                            {item?.action || ''}
                          </p>
                        </Col>
                        <Col span={12} className="dateDataColumn">
                          <p>
                            {moment(item?.action_time || new Date()).format("YYYY-MM-DD HH:mm:ss")}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="processSection">
              <p className="title">
                各工程レベル
              </p>
              <div className="operation-history-scroll">
                <div className="process-level-container">
                  {
                    (
                      (currentUserRecord && currentUserRecord?.machining_processes_detail) || []
                    ).map((item: any, index: number) => {
                      return (
                        <ProgressContent key={index} item={item} />
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
      </AdminLayout>
      <div className="skill-check-footer">
        <div style={{ cursor: 'pointer', display: (page === 1) ? 'none' : 'block' }} onClick={movePrevRecord}>
          <IPrevious />
        </div>
        <p className="currentPage">
          {page} / {totalCount}
        </p>
        <div style={{ cursor: 'pointer', display: (page === totalCount) ? 'none' : 'block' }} onClick={moveNextRecord}>
          <INext />
        </div>
      </div>
    </>
  );
};
export default SkillCheck;
