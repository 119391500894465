import { put, takeLatest } from "redux-saga/effects";

import axios from "../../config/axios";
import constant from "../../constant/action";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { AdminLoginParams, AdminLogoutParams } from "types/auth";
import { setIsAdminLoggedIn, setAdminLoginError } from "store/duck/adminAuthSlice";

export const adminLoginAction = createAction<AdminLoginParams>(constant.ADMIN_LOGIN);
export const adminLogoutAction = createAction<AdminLogoutParams>(constant.ADMIN_LOGOUT);

function* login(action: PayloadAction<AdminLoginParams>) {

  try {
    const url = constant.ADMIN_LOGIN;
    const { account_id, factory_code, password } = action.payload;
    const body = {
      account_id,
      factory_code,
      password,
    };
    const { data } = yield axios.post(url, body);

    if (data) {
      yield put(setIsAdminLoggedIn(true));
      localStorage.setItem("token", data?.access_token);
    }
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      yield put(setAdminLoginError(true));
    }
  }
}

function* logout(action: PayloadAction<AdminLogoutParams>) {
  try {
    const url = constant.ADMIN_LOGOUT;
    const { logout_type } = action.payload;
    const { data } = yield axios.delete(url, {data: { request_type: logout_type }});

    if (data) {
      if (logout_type === "primary") {
        yield put(setIsAdminLoggedIn(false));
        localStorage.removeItem('token');
        if (localStorage.getItem('secondary_token')) {
          localStorage.removeItem('secondary_token');
        }
      }
      if (logout_type === "secondary") {
        localStorage.removeItem('secondary_token');
      }
    }
  } catch (error) {}
}

function* authSaga() {
  yield takeLatest(adminLoginAction, login);
  yield takeLatest(adminLogoutAction, logout);
}
export default authSaga;
