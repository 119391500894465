import { Box } from "@mui/material";

export const INext = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.839"
        height="29.839"
        viewBox="733 151 29.839 29.839"
      >
        <path
          d="M747.92 151c-8.237 0-14.92 6.681-14.92 14.92 0 8.238 6.683 14.92 14.92 14.92 8.24 0 14.92-6.682 14.92-14.92 0-8.239-6.68-14.92-14.92-14.92Zm-1.866 7.456c.258 0 .506.054.73.151.226.094.426.238.596.404l2.79 2.79 2.788 2.792c.17.173.312.37.408.596a1.886 1.886 0 0 1 0 1.463 1.964 1.964 0 0 1-.408.594l-2.788 2.785-2.79 2.792a1.77 1.77 0 0 1-.595.408c-.225.094-.473.15-.731.15a1.865 1.865 0 0 1-1.314-3.192l2.139-2.133 2.134-2.137-2.134-2.134-2.139-2.14a1.84 1.84 0 0 1-.551-1.32c0-.516.208-.98.545-1.318a1.865 1.865 0 0 1 1.32-.551Z"
          fill="#fff"
          fill-rule="evenodd"
          data-name="パス 1339"
        />
      </svg>
    </Box>
  );
};
