import { Box } from "@mui/material";

export const ITime = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31.174"
        height="31.174"
        viewBox="-2372.446 -2358.446 31.174 31.174"
      >
        <g data-name="グループ 2596">
          <path
            d="M-2347.666-2344.058h-1.199a1.2 1.2 0 0 0 0 2.398h1.2a1.2 1.2 0 0 0 0-2.398m-9.193 7.994a1.2 1.2 0 0 0-1.2 1.199v1.199a1.2 1.2 0 0 0 2.399 0v-1.2a1.2 1.2 0 0 0-1.2-1.198m-7.993-7.994h-1.199a1.2 1.2 0 0 0 0 2.398h1.2a1.2 1.2 0 0 0 0-2.398m7.993-9.192a1.2 1.2 0 0 0-1.2 1.199v1.199a1.2 1.2 0 0 0 2.399 0v-1.2a1.2 1.2 0 0 0-1.2-1.198"
            fill="#fff"
            fill-rule="evenodd"
            data-name="パス 7859"
          />
          <path
            d="M-2356.858-2358.446c-8.609 0-15.588 6.979-15.588 15.587 0 8.609 6.98 15.588 15.588 15.588 8.608 0 15.587-6.979 15.587-15.588 0-8.608-6.979-15.587-15.587-15.587m0 27.178c-6.391 0-11.59-5.2-11.59-11.59 0-6.392 5.199-11.591 11.59-11.591 6.39 0 11.59 5.2 11.59 11.59 0 6.391-5.2 11.59-11.59 11.59m5.228-18.255-4.723 4.722a2.39 2.39 0 0 0-.505-.055c-.253 0-.493.05-.722.123l-3.659-3.66a1.199 1.199 0 1 0-1.696 1.696l3.734 3.734a2.398 2.398 0 1 0 4.74.505c0-.253-.05-.493-.122-.722l4.648-4.648a1.198 1.198 0 1 0-1.695-1.695"
            fill="#fff"
            fill-rule="evenodd"
            data-name="パス 7860"
          />
        </g>
      </svg>
    </Box>
  );
};
