import { useLocation } from "react-router-dom";
import "./style.scss";
import styled from "styled-components";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { adminLogoutAction } from "store/sagas/adminAuthSaga";
import { AdminLogoutParams } from "types/auth";

const ContentImage = styled.div`
  background-image: url("https://i.imgur.com/o7Q4JGg.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 707px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
  @media only screen and (max-width: 1280px) {
    min-height: 800px;
  }
  @media only screen and (max-width: 1150px) {
    min-height: 0;
  }
`;
interface Props {
  children?: ReactNode;
  // any props that come into the component
}
const AdminLayout = ({ children, ...props }: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const handleLogout = () => {
    const data: AdminLogoutParams = {
      logout_type: "primary",
    }
    dispatch(adminLogoutAction(data));
    localStorage.removeItem('selected_process');
    localStorage.removeItem('selected_process_name');
    localStorage.removeItem('cachedProcesses');
  };

  return (
    <>
      <div className="adminLayoutContainer">
        <div className="header-admin">
          <p className="header-logo">
            F-Factory
          </p>
          {location?.pathname?.includes("/admin/skill-check") && (
            <button
              onClick={handleLogout}
              className="returnBtn"
            >
              戻る
            </button>
          )}
        </div>
        <ContentImage>
          <div className="childrenWrapper">{children}</div>
        </ContentImage>
      </div>
    </>
  );
};
export default AdminLayout;
