import { ReactNode, useState, useEffect } from "react";
import { IMobile } from "../../../icons/IMobile";
import "./style.scss";
import styled from "styled-components";
import { IClose } from "../../../icons/IClose";
import { Button, Form, Input, Modal } from "antd";
import { toast } from "utils/notification";
import constant from "constant/action";
import axios from "config/axios";
import ChangePasswordModal from "components/ChangePasswordModal";
import LevelTag from "components/LevelTag";
import UnreadItem from "components/UnreadItem";

const ContentImage = styled.div`
  background-image: url("https://i.imgur.com/o7Q4JGg.png") !important;
  min-height: 707px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  @media only screen and (max-width: 1280px) {
    min-height: 600px;
  }
`;
interface Props {
  children?: ReactNode;
  // any props that come into the component
}
interface Value {
  password: string;
}
interface ChangePasswordParams {
  user_id: number;
  password: string;
}
const WorkerLayout = ({ children, ...props }: any) => {
  const [isOpenModalAccountName, setIsOpenModalAccountName] = useState(false);
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
  const [form] = Form.useForm();
  const [requiredError, setRequiredError] = useState<boolean>(false);

  const handleChangePassword = async (value: Value) => {
    if (!value.password || !props.currentUserRecord) {
      setRequiredError(true);
      return;
    }

    try {
      const data: ChangePasswordParams = {
        password: value.password,
        user_id: props.currentUserRecord.id,
      };
      const url = constant.CHANGE_PASSWORD;
      const response = await axios.put(url, data);
      if (response && response.data) {
        setUpdatePasswordSuccess(true);
        form.resetFields();
      }
    } catch (error: any) {
      if (error && error.response) {
        const errorMessage = error.response?.data?.error;
        toast('error', errorMessage);
      }
    }

  }

  useEffect(() => {
    let updatePasswordSuccessTimeout: NodeJS.Timeout;
    if (updatePasswordSuccess) {
      updatePasswordSuccessTimeout = setTimeout(() => {
        setUpdatePasswordSuccess(false);
      }, 5000);
    }
    return () => clearTimeout(updatePasswordSuccessTimeout);
  }, [updatePasswordSuccess]);

  useEffect(() => {
    let requiredErrorTimeout: NodeJS.Timeout;
    if (requiredError) {
      requiredErrorTimeout = setTimeout(() => {
        setRequiredError(false);
      }, 5000);
    }
    return () => clearTimeout(requiredErrorTimeout);
  }, [requiredError]);

  return (
    <>
      <div className="header-admin">
        <div className="accountInfo" onClick={(e) => e.stopPropagation()}>
          <div className="accountName">
            <p className="label">
              作業者名
            </p>
            <p className="value">
              {props.currentUserRecord?.name}
            </p>
          </div>
          <div className="accountId">
            <p className="label">
              作業者コード
            </p>
            <p className="value">
              {props.currentUserRecord?.account_id}
            </p>
          </div>
          <LevelTag type="worker" level={+props.currentUserRecord?.level || 0} screen="working" />
        </div>
        <div className="rightSideWrapper">
          <UnreadItem amount={props.currentUserRecord?.unread_items || 0} />
          <div
            className="changePassword"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenModalAccountName(true);
            }}
          >
            <IMobile />
            <p>
              アプリ用パスワード設定
            </p>
          </div>
        </div>
      </div>
      <ContentImage>
        <div className="childrenWrapper">{children}</div>
      </ContentImage>
      <ChangePasswordModal 
        open={isOpenModalAccountName}
        setOpen={setIsOpenModalAccountName}
        accountName={props.currentUserRecord?.name}
        currentUserRecord={props.currentUserRecord}
      />
    </>
  );
};
export default WorkerLayout;
