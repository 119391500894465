import React, { useEffect, useState } from "react";
import AdminLayout from "../admin/adminLayout";
import { Input, Form, Button } from "antd";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginAction } from "../../store/sagas/adminAuthSaga";
import { RootState } from "store";
import { AdminLoginParams } from "types/auth";
import { setAdminLoginError } from "store/duck/adminAuthSlice";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAdminLoggedIn, adminLoginError } = useSelector((state: RootState) => state.adminAuth);
  const [targetRoute, setTargetRoute] = useState<string | null>(null);
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<boolean>(false);

  const onSubmitLogin = async (values: any) => {
    if (!values.account_id || !values.factory_code || !values.password) {;
      setRequiredError(true);
      return;
    }
    const data: AdminLoginParams = {
      account_id: values?.account_id,
      factory_code: values?.factory_code,
      password: values?.password,
    };
    dispatch(adminLoginAction(data));
  };

  useEffect(() => {
    if (adminLoginError) {
      form.resetFields();
      setLoginError(true);
      dispatch(setAdminLoginError(false));
    }
  }, [adminLoginError]);

  useEffect(() => {
    if (isAdminLoggedIn) {
      if (targetRoute) {
        navigate(targetRoute);
      } else {
        navigate("/admin/device-settings");
      }
    }
  }, [isAdminLoggedIn, targetRoute, navigate]);

  useEffect(() => {
    let requiredErrorTimeout: NodeJS.Timeout;
    if (requiredError) {
      requiredErrorTimeout = setTimeout(() => {
        setRequiredError(false);
      }, 5000);
    }
    return () => clearTimeout(requiredErrorTimeout);
  }, [requiredError]);

  useEffect(() => {
    let loginErrorTimeout: NodeJS.Timeout;
    if (loginError) {
      loginErrorTimeout = setTimeout(() => {
        setLoginError(false);
      }, 5000);
    }
    return () => clearTimeout(loginErrorTimeout);
  }, [loginError]);

  return (
    <>
      <AdminLayout>
        <div className="login-form">
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={(values) => {
              onSubmitLogin(values);
            }}
          >
            <p className="title-login">工場ID</p>
            <Form.Item name="factory_code">
              <Input className="input-login" />
            </Form.Item>
            <p className="title-login">管理者ID</p>
            <Form.Item name="account_id">
              <Input className="input-login" />
            </Form.Item>
            <p className="title-login">パスワード</p>
            <Form.Item name="password">
              <Input type="password" className="input-login" />
            </Form.Item>
            <div className="flex min-w-[590px] gap-x-[30px]">
              <Button
                // htmlType="submit"
                className="button-login font-noto-sans"
                onClick={() => {
                    setTargetRoute("/admin/skill-check");
                    form.submit();
                  // navigate("/admin/skill-check");
                }}
              >
                スキル確認
              </Button>
              <Button
                className="button-login bt-settings font-noto-sans"
                // htmlType="submit"
                onClick={() => {
                  setTargetRoute("/admin/device-settings");
                  form.submit();
                  // navigate("/admin/device-settings");
                }}
              >
                設定
              </Button>
            </div>
          </Form>
          <div className="font-noto-sans h-12 pt-6 font-bold text-center text-[16px] text-red-600">
            {loginError ? '情報は正しくありません。再入力をしてください。' 
            : requiredError ? 'このフィールドは入力必須です'
            : ''}
          </div>
        </div>
      </AdminLayout>
    </>
  );
};

export default Login;
