import React from "react";
import { Progress } from "antd";
import "./style.scss";
import LevelTag from "components/LevelTag";
interface LevelProgressBarProps {
  type: "worker" | "process";
  level: number;
  percentToBoostLevel: number;
  style?: React.CSSProperties;
}

const LevelProgressBar: React.FC<LevelProgressBarProps> = ({
  type,
  level,
  percentToBoostLevel,
  style = {},
}) => {
  const strokeColor = type === "worker" ? "#83ACD5" : "#83d59f"
  return (
    <div className="levelProgressBarWrapper" style={style}>
      <LevelTag type={type} screen="working" />
      <p className="level">
        <span>Lv.{level || 0}</span>まで
      </p>
      <div className="progressWrapper">
        <Progress
          showInfo={false}
          percent={percentToBoostLevel || 0}
          strokeColor={strokeColor}
          size="small"
          className="progressBar"
        />
      </div>
    </div>
  )
}

export default LevelProgressBar;