import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const token = localStorage.getItem("token");

const initialState = {
  isAdminLoggedIn: token ? true : false,
  adminLoginError: false,
};

export const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    setIsAdminLoggedIn: (state, action) => {
      state.isAdminLoggedIn = action.payload;
    },
    setAdminLoginError: (state, action) => {
      state.adminLoginError = action.payload;
    }
  },
});

export const { setIsAdminLoggedIn, setAdminLoginError } = adminAuthSlice.actions;

const adminAuthSelector = (store: RootState) => store.adminAuth;

export default adminAuthSlice.reducer;
 