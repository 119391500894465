import { Box } from "@mui/material";

export const IClose = () => {
  return (
    <Box display="flex">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.841"
        height="29.839"
        viewBox="367 157 29.841 29.839"
      >
        <path
          d="M381.92 157c-8.237 0-14.92 6.683-14.92 14.92 0 8.236 6.683 14.92 14.92 14.92 8.24 0 14.921-6.684 14.921-14.92 0-8.237-6.68-14.92-14.921-14.92Zm-4.27 8.014 4.27 4.27 4.272-4.27a1.854 1.854 0 0 1 1.322-.554c1.03 0 1.87.837 1.87 1.865 0 .519-.217.987-.558 1.324l-4.269 4.27 4.269 4.273c.341.337.558.806.558 1.326a1.866 1.866 0 0 1-3.192 1.312l-4.272-4.273-4.27 4.273a1.86 1.86 0 0 1-1.325.55 1.863 1.863 0 0 1-1.311-3.188l4.27-4.273-4.27-4.27a1.856 1.856 0 0 1-.554-1.324c0-1.028.835-1.865 1.865-1.865.519 0 .987.212 1.324.554Z"
          fill="#7b7b7b"
          fill-rule="evenodd"
          data-name="パス 1335"
        />
      </svg>
    </Box>
  );
};
