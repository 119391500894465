import "./style.scss";

interface UnreadItemProps {
  amount: number;
}

const UnreadItem: React.FC<UnreadItemProps> = ({ amount }) => {
  const renderedAmount = amount < 10 ? `0${amount}` : amount;
  return (
    <p className="unreadItemContent">
      <span>未確認アイテム</span>
      <span className="amount">{renderedAmount}</span>
      <span>件</span>
    </p>
  );
};

export default UnreadItem;