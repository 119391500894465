import { Button, Form, Image, Select } from "antd";
import AdminLayout from "../adminLayout";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { IBottom } from "icons/IBottom";
import { useDispatch } from "react-redux";
import { adminLogoutAction } from "store/sagas/adminAuthSaga";
import { AdminLogoutParams } from "types/auth";
import axios from "config/axios";
import constant from "constant/action";
import { useEffect, useState } from "react";
import { MachiningProcessAttribute, MachiningProcessSelectOption } from "types/machiningProcess";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const DeviceSettings = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getSelectedProcessId = () => {
    const selectedProcessId = localStorage.getItem("selected_process");
    const selectedProcessName = localStorage.getItem("selected_process_name");
    if (selectedProcessId && selectedProcessName) {
      return {
        value: Number(selectedProcessId),
        label: selectedProcessName
      };
    }
    return null;
  }
  const [processList, setProcessList] = useState<MachiningProcessSelectOption[]>([
    { value: -1, label: "工程を選択してください" }
  ]);
  const [selectedProcess, setSelectedProcess] = useState<MachiningProcessSelectOption | null>(() => getSelectedProcessId());

  const handleLogout = () => {
    const data: AdminLogoutParams = {
      logout_type: "primary"
    };
    dispatch(adminLogoutAction(data));
    localStorage.removeItem('selected_process');
    localStorage.removeItem('selected_process_name');
    localStorage.removeItem('cachedProcesses');
    // navigate("/admin/login");
  }
  
  useEffect(() => {
    const fetchtMachiningProcesses = async () => {
      try {
        const url = constant.MACHINING_PROCESSES;
        const response = await axios.get(url);
        if (response && response.data) {
          const processOptions = response.data.data.map((item: MachiningProcessAttribute) => {
            return { value: item.id, label: item.process_jpn };
          });
          setProcessList((prev) => [...prev, ...processOptions]);
        }
      } catch (error) {
        // error
      }
    }
    fetchtMachiningProcesses();
  }, [])

  const handleSelectProcess = (value: any, option: MachiningProcessSelectOption) => {
    if (value === -1) {
      setSelectedProcess(null);
      return;
    }
    setSelectedProcess(option);
  };

  const handleDecide = () => {
    if (selectedProcess !== null) {
      localStorage.setItem('selected_process', String(selectedProcess.value));
      localStorage.setItem('selected_process_name', selectedProcess.label);
      navigate(`/admin/manufacturing-start?process_id=${selectedProcess.value}`);
    }
  };

  return (
    <>
      <AdminLayout>
        <div className="setting-form">
          <Form {...layout} form={form} name="nest-messages">
            <p className="title-device">工程選択</p>
            <Form.Item name="device">
              <Select
                // value={selectedProcess?.value}
                defaultValue={selectedProcess?.label || -1}
                className="select-device"
                options={processList}
                suffixIcon={
                  <Image
                    preview={false}
                    src="https://i.imgur.com/0mU6EI5.png"
                  />
                }
                onSelect={handleSelectProcess}
              />
            </Form.Item>
            <div className="flex flex-col items-center gap-y-[40px] pt-[100px]">
              <Button
                className="button-decide font-noto-sans text-[16px] not-italic font-bold"
                onClick={handleDecide}
              >
                決定
              </Button>
              <Button
                className="button-decide bt-return font-noto-sans text-[16px] not-italic font-bold"
                onClick={handleLogout}
              >
                戻る
              </Button>
            </div>
          </Form>
        </div>
      </AdminLayout>
    </>
  );
};
export default DeviceSettings;
